import React from "react";
import { Container } from "react-bootstrap";
import {Layout} from "../../../components/Layout"
import ProductBanner from "../../../components/product_banners/product_banners";
import "./../../inversiones.css";
import Banner from "../../../imgs/bg_glosario.jpg";
import { SalWrapper } from "react-sal"
import GlossariesList from "../../../components/glosario-financiero/GlossariesList";

const GlosariosPage = () => {
    const pageTitle = 'Invertí en todos los activos del Mercado Financiero | Balanz';
    const pageDesc = 'Potenciá tus rendimientos. Tenemos disponibles todos los instrumentos del mercado y la mejor plataforma de inversiones para que tus ahorros rindan más.';

  let text = `Aprendé los términos financieros más importantes y empezá a<br/> <span class='highlightsbold'>invertir como un experto.</span>`;
  const page = (
      <>
      <ProductBanner
            headingType="h1"
            bannerContainerClassName="bannerUniversity"
            bannerTrasparecy="bannerMask"
            productTitle="Glosario Financiero"
            bannerImage={Banner}
            bannerImageClassName="cedearsBannerImage"
            textContainerClassName="textContainer"
            bannerText={text}
            bannerTextClassName="bannerText1"
            bannerImageMobile={Banner}
            mobile={false}
      />
      <SalWrapper>

      <section className="fullwidth inversiones-btns pb-0">
          <div className="lightgrey pb-4 pb-sm-5">
              <Container>
                <GlossariesList />
              </Container>
          </div>
      </section>
            

        </SalWrapper>
    </>
  );

  return (
      <main>
          <Layout title={pageTitle} description={pageDesc} childrem={page} category={"glosario-financiero"}></Layout>
          
      </main>
  );
};

// Step 3: Export your component
export default GlosariosPage;
